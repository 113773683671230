.modal {
  position: absolute;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: #ffffff;
  box-shadow: 0px 3px 30px rgba(0, 0, 0, 0.08);
  border-radius: 15px;
  border: none;
  display: flex;
  flex-direction: column;
  padding: 40px 24px;
  &Container {
    font-family: 'Roboto';
    position: relative;
    max-width: 518px;
  }
  &Title {
    color: #2d3035;
    text-align: center;
    font-size: 24px;
    font-weight: 600;
    line-height: 34px;
  }
  &Icon {
    position: absolute;
    right: -4px;
    top: -20px;
    cursor: pointer;
  }
  &Button {
    width: 100%;
    text-align: center;
    font-family: 'Roboto';
    font-size: 16px;
    font-weight: 500;
    border: none;
    border-radius: 4px;
    background: #5796d2;
    color: #fff;
    margin-top: 20px;
    padding: 14px;
    cursor: pointer;
    &:hover {
      opacity: 0.7;
    }
  }
  &Description {
    color: #2d3035;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 16px 0 20px 0;
  }
  &Error {
    color: #c24040;
    text-align: center;
    font-size: 18px;
    font-weight: 400;
    line-height: 28px;
    padding: 16px 0 0 0;
  }
  &Label {
    display: flex;
    flex-direction: column;
    row-gap: 10px;
    color: #303134;
    font-size: 14px;
    font-weight: 400;
    margin-bottom: 20px;
    input {
      border-radius: 4px;
      border: 1px solid #b3bcc9;
      font-family: 'Roboto';
      font-size: 16px;
      font-weight: 400;
      line-height: 25px;
      padding: 10px 14px;
      &::placeholder {
        color: #b3bcc9;
      }
    }
  }
  &Checkbox {
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    &Item {
      display: flex;
      column-gap: 6px;
      color: #2d3035;
      font-family: 'Roboto';
      font-size: 14px;
      font-weight: 400;
    }
  }
}

.modalCheckboxItem {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 12px;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
/* Hide the browser's default checkbox */
.modalCheckboxItem input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: -2px;
  left: 0;
  height: 20px;
  width: 20px;
  border: 1.5px solid #5796d2;
  border-radius: 4px;
}
/* When the checkbox is checked, add a blue background */
.modalCheckboxItem input:checked ~ .checkmark {
  background-color: #5796d2;
}
/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}
/* Show the checkmark when checked */
.modalCheckboxItem input:checked ~ .checkmark:after {
  display: block;
}
/* Style the checkmark/indicator */
.modalCheckboxItem .checkmark:after {
  left: 5px;
  top: 0px;
  width: 5px;
  height: 10px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

@media screen and (max-width: 600px) {
  .modal {
    width: 100%;
    &Title {
      font-size: 20px;
      line-height: 26px;
    }
    &Button {
      font-size: 14px;
      padding: 10px;
    }
    &Description {
      font-size: 14px;
      line-height: 23px;
    }
    &Error {
      font-size: 14px;
    }
    &Checkbox {
      &Item {
        font-size: 12px;
      }
    }
  }
}
