.card {
    &Container {
      border: 1px solid #d2dbe8;
      border-radius: 10px;
      padding: 12px 16px;
      margin: 0 4px;

      &Web {
        svg {
            width: 14px;
                height: 14px;
            path {
                fill: #5796D2;
                width: 14px;
                height: 14px;
            }
        }
      }

      &Contact {
        svg {
            path {
                fill: #FFFFFF;
            }
        }
      }
    }
    &Header {
      display: flex;
      justify-content: space-between;
      margin-bottom: 16px;
  
      &Address{
        width: calc(100% - 50px);
        h5 {
          display: flex;
          flex-direction: column;
          display: block;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          font-size: 14px;
          font-weight: 400;
          line-height: 22px;
          color: #2d3035;
          span {
            display: block;
            font-weight: 500;
          }
        }
        svg {
          flex-shrink: 0;
        }
      }
    }
    &Budget {
      font-size: 20px;
      font-weight: 500;
      line-height: 28px;
      color: #196faf;
      text-align: center;
      margin-bottom: 16px;
    }
    &Data {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      column-gap: 14px;
      margin-bottom: 12px;
      li {
        display: flex;
        align-items: center;
        justify-content: center;
        column-gap: 8px;
        background-color: #f8fafd;
        border-radius: 5px;
        padding: 4px 8px;
      }
    }
    &Info {
      display: flex;
      align-items: center;
      column-gap: 4px;
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      margin-bottom: 4px;
      span {
        display: block;
        font-weight: 500;
        background-color: #f8fafd;
        border-radius: 4px;
        padding: 4px;
      }
    }
    &Buttons {
      display: flex;
      flex-wrap: nowrap;
      justify-content: space-between;
      align-items: center;
      column-gap: 4px;
      margin: 8px 0 12px;
    }
    &Btn {
      display: block;
      width: 100%;
      border: none;
      border-radius: 4px;
      background-color: unset;
      font-family: 'Roboto';
      padding: 8px 0;
      font-size: 14px;
      font-weight: 500;
      line-height: 25px;
      text-align: center;
      color: #ffffff;
      &:disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
      &:hover {
        filter: brightness(1.1);
      }
      &Contacts {
        background: #ef6f22;
        border: 2px solid #ef6f22;
        color: #ffffff;
      }
      &Map {
        border: 2px solid #5796d2;
        color: #5796d2;
      }
    }
    &Time {
      font-size: 12px;
      font-weight: 400;
      line-height: 14.06px;
      color: #717780;
    }
  }
  
  .sortedItem {
    position: relative;
    text-align: center;
    font-weight: 400;
    font-size: 16px;
    line-height: 156%;
    color: #2d3035;
    width: 175px;
    //padding: 16px 10px;
    &:hover .hide {
      display: block;
      text-align: center;
      position: absolute;
      top: -50px;
      left: 0px;
      width: 250px;
      background: #ffffff;
      border-radius: 4px;
      box-shadow: 0px 2px 8px rgba(0, 0, 0, 0.15);
      font-family: 'Roboto';
      font-weight: 400;
      font-size: 16px;
      line-height: 25px;
      color: #2d3035;
      padding: 6px 8px 0 8px;
    }
  }
  
  .hide {
    display: none;
  }
  
  .hide {
    &Triangle {
      width: 0px;
      height: 0px;
      position: relative;
      left: 105px;
      top: 8px;
      border-left: 8px solid transparent;
      border-right: 8px solid transparent;
      border-top: 8px solid #ffffff;
    }
  }
  